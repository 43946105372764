<template>
    <div>
        <div class="headerBox">
           <el-input placeholder="输入姓名查找" v-model.trim="name" size="small"></el-input>
           <el-input placeholder="输入治疗号查找" v-model.trim="admissionNo" size="small"></el-input>
           <el-button type="primary" size="small" @click="selList">查找</el-button>
           <el-button type="primary" size="small" @click="getUserLs(null)">刷新</el-button>
        </div>
        <el-table :data="UserLs" border size="small">
            <el-table-column  prop="id"  label="序号" >
                <template  slot-scope="scope">
                      {{ scope.$index +1 }}
                </template>  
           </el-table-column>
           <el-table-column  prop="name"  label="姓名" ></el-table-column>
           <el-table-column  prop="admissionNo"  label="治疗号" >
            <template slot-scope="scope">
                <el-tag> 
                        {{ scope.row.admissionNo }} 
                        <el-badge :value="scope.row.count" size="small" class="item" ></el-badge>
               </el-tag> 
            </template>
           </el-table-column>
           <el-table-column  prop="sex"  label="性别" >
               <template slot-scope="scope">
                   <el-tag v-if="scope.row.sex!==null" :type="(scope.row.sex==0)?'blue':'danger'">
                      {{ (scope.row.sex==0)?'男':'女' }}
                   </el-tag>
                   <span v-else> {{ scope.row.sex }}</span>
               </template>
           </el-table-column>
           <el-table-column  prop="age"  label="年龄" ></el-table-column>
           <el-table-column  prop="createdTime"  label="时间" ></el-table-column>
           <el-table-column  prop="updatedTime"  label="更新时间" > </el-table-column>
           <el-table-column   label="操作" >
                <template  slot-scope="scope">
                    <el-button type="text" @click="haderEdit(scope.row)" style="margin-right: 10px;"> 编辑 </el-button>
                    <el-popconfirm title="你确定删除该数据吗？" @confirm="deleteRow(scope.$index,UserLs)">
                         <el-button slot="reference" type="text" size="small"  > 删除 </el-button>
                    </el-popconfirm>
                    <el-button size="small" type="text"  style="margin-left: 10px;">
                      <RouterLink :to="{path:'/home/details/'+scope.row.admissionNo,
                                   query:{age:scope.row.age,sex:scope.row.sex||'',count:scope.row.count}}">查看详情 </RouterLink>   
                     </el-button>
              </template>  
           </el-table-column>
           </el-table>
           <el-pagination
                @size-change="(val)=>{ pageOptions.pageSize = val;pageState = true}"
                @current-change="(val)=> { pageOptions.pageNo = val;pageState = true}"
                :page-sizes="[30,50,100,200]"
                :page-size="30"
                layout="total, sizes, prev, pager, next, jumper"
                style="float:right"
                :total="pageOptions.total">
          </el-pagination>
           <!--弹出信息框更改数据-->
           <el-dialog
            :visible.sync="dialogVisible"
            width="45%"
            >
            <el-descriptions class="margin-top" :column="2" size="small" border>                 
                <el-descriptions-item>
                    <template slot="label">
                        序号
                    </template>
                    {{ userinfo.id }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        姓名
                    </template>
                    <el-input v-model="userinfo.name" size="small"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        治疗号
                    </template>
                    <el-input v-model="userinfo.admissionNo" size="small"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        年龄
                    </template>
                          <el-input v-model="userinfo.age" size="small"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        性别
                    </template>
                    <el-radio v-model="userinfo.sex" label="0">男</el-radio>
                    <el-radio v-model="userinfo.sex" label="1">女</el-radio>
                    </el-descriptions-item>
                </el-descriptions>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                    <el-button type="primary" @click="saveUserinfo" size="small">确 定</el-button>
                </span>
            </el-dialog>
    </div>
</template>
<script>
import { getUsers,updateUsers,deleteUsers} from '@/api/user'
export default {
    data(){
        return {
            UserLs:[],
            name:'',
            admissionNo:'',
            pageOptions:{
                total:0, 
                pageNo:1,
                pageSize:30,
                oderBy:'id'
            },
            pageState:false,
            dialogVisible: false,
            userinfo:{}     
        }
    },
    watch:{
        pageState(val,oldVal){
          if(val){
            this.getUserLs(this.pageOptions); 
          }
        this.pageState = false;
        }
    },
    methods:{
        async getUserLs(opt){
            if(opt===null){
                opt = {
                    pageNo:1,
                    pageSize:30,
                    oderBy:'id'
                }
            }
            const res = await getUsers(opt);
            if(res.code === 200){
                this.UserLs = res.rows;
                this.pageOptions.total = res.total;
            }
        },
        haderEdit(rows){
            this.dialogVisible = true;
            const item = rows;
            item.sex = ( item.sex ==null)?null:String(item.sex);
            this.userinfo = rows
        },
        selList(){
            const opt = {
                    pageNo:1,
                    pageSize:30,
                    oderBy:'id'
            };
            if(!this.name && !this.admissionNo){
                this.$message.error('请输入查找信息');
                return;
            }
           if(this.name){
                opt.name = this.name
           }
           if(this.admissionNo){
               opt.admissionNo = this.admissionNo;
           }
           this.getUserLs(opt);

        },

       async saveUserinfo(){
           const res = await updateUsers(this.userinfo);
           if(res.code === 200){
            this.$message.success("更新成功");
            this.dialogVisible = false;
           }
        },
        async deleteRow(index,rows) {
        const info = rows[index];
        const res = await deleteUsers(info.id);
        if(res.code === 200 ){
          rows.splice(index, 1);
          this.pageOptions.total = this.total -1;
          this.$message.success("删除成功");
        }else{
          this.$message.error("删除有误");
        }
      },
      
    },
    created(){
          this.getUserLs(this.pageOptions);
    }
}
</script>
<style scoped lang="scss">
.el-badge__content.is-fixed{
    position: absolute;
    top: 10px;
    right: 0px;
    transform: translateY(-50%) translateX(100%);
    }
   .headerBox{
    .el-input{
        margin: 10px;
        width:150px;
    }
   }
</style>